<template>
    <div class="d-flex flex-column">
        <div v-for="(ob, i) in modelValue" :key="i" class="mb-1">
            <div class="d-flex">
                <div>
                    <span class="fst-italic">{{ formatDate(ob.date) }}</span>
                    <p class="fs-6">
                        {{ ob.body }}
                    </p>
                </div>
                <div class="ms-auto">
                    <button class="btn btn-success btn-sm px-2" @click="$emit('onRender', i)">
                        Generar
                    </button>
                </div>
            </div>
            <hr />
        </div>
        <textarea class="form-control border mb-2" rows="4" v-model="body"></textarea>
        <button class="btn btn-success ms-auto" @click="handleAddEvolucion">Agregar</button>
    </div>
</template>
<script>
import { ref } from 'vue';
import { DateTime } from 'luxon';

export default {
    name: 'EvolucionForm',
    props: {
        modelValue: {
            type: Array,
            default: () => [],
        },
        disabled: Boolean,
    },
    setup(props, { emit }) {
        const body = ref('');
        // const state = computed({
        //     get() {
        //         return props.modelValue;
        //     },
        //     set(value) {
        //         emit('update:modelValue', value);
        //     },
        // });

        const handleAddEvolucion = () => {
            emit('update:modelValue', [
                ...props.modelValue,
                { body: body.value, date: DateTime.now().toISO() },
            ]);
            emit('onChange');
            body.value = '';
        };

        const formatDate = (date) => DateTime.fromISO(date).toFormat('yyyy-MM-dd');
        // const formatDate = (date) => DateTime.fromMillis(date).toISO('yyyy-MM-dd');
        return {
            // state,
            body,
            handleAddEvolucion,
            formatDate,
        };
    },
};
</script>
<style></style>
