import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useSnackbar } from 'vue3-snackbar';

export default function useConsultaForm(service) {
    const store = useStore();
    const snackbar = useSnackbar();
    const formType = ref();

    const state = reactive({
        id: '',
        consecutive: '',
        paciente: '',
        date: '',
        doctor: [],
        institucion: '',
        specialty: [],
        data: [],
        evoluciones: [],
        renders: '',
        key: '',
        documentStatus: '',
        status: '',
        setup: '',
        observaciones: [],
        citaId: null,
        formType: '',
    });

    const disabled = computed(() => {
        if (state.id && state.documentStatus >= 2) {
            return true;
        }
        return false;
    });

    const setState = (c) => {
        state.id = c.id;
        state.consecutive = c.consecutive;
        state.paciente = c.paciente.id;
        state.date = c.date;
        state.doctor = c.doctor.map((d) => d.id);
        state.institucion = c.institucion;
        state.specialty = c.specialty;
        state.data = c.data;
        state.evoluciones = c.evoluciones;
        state.renders = c.renders;
        state.key = c.key;
        state.documentStatus = c.documentStatus;
        state.status = c.status;
        state.setup = c.setup;
        state.observaciones = c.observaciones;
        state.citaId = null;
        state.formType = formType.value;
    };

    /** ***** Validator ***** */
    const rules = computed(() => ({
        paciente: { required },
        doctor: { required },
        institucion: { required },
        date: { required },
    }));

    const v = useVuelidate(rules, state);

    const resetForm = () => {
        state.id = '';
        state.consecutive = '';
        state.paciente = '';
        state.date = '';
        state.doctor = [];
        state.institucion = '';
        state.specialty = [];
        state.data = {};
        state.evoluciones = [];
        state.renders = '';
        state.key = '';
        state.documentStatus = '';
        state.status = '';
        state.setup = '';
        state.observaciones = [];
        state.citaId = null;
        state.formType = formType.value;
        v.value.$reset();
    };

    const handleAction = async (action, data) => {
        if (await v.value.$validate()) {
            let text;
            switch (action) {
                case 'create':
                case 'create_view':
                    if (state.citaId) await service.createConsultaWithCita(state);
                    else await service.createConsulta(state);
                    if (action === 'create_view') {
                        store.dispatch('ContentManager/openWindow', {
                            id: `ConsultaOftalmologiaForm_${service.consulta.value.id}`,
                            component: 'ConsultaOftalmologiaForm',
                            name: `Consulta ${service.consulta.value.consecutive}`,
                            params: {
                                title: 'Consulta',
                                headerTitle: `Ver Consulta ${service.consulta.value.consecutive}`,
                                item: { ...service.consulta.value },
                            },
                        });
                    }
                    text = 'Consulta creda con exito';
                    resetForm();
                    break;
                case 'save':
                    await service.updateConsulta(state.id, state);
                    setState(service.consulta.value);
                    text = 'Consulta actualizada con exito';
                    break;
                case 'save_sh':
                    await service.updateConsulta(state.id, state);
                    setState(service.consulta.value);
                    break;
                case 'close':
                    await service.updateConsulta(state.id, state);
                    await service.closeConsulta(state.id);
                    setState(service.consulta.value);
                    text = 'Consulta finalizada con exito';
                    break;
                case 'publish':
                    await service.publishConsulta(state.id);
                    setState(service.consulta.value);
                    text = 'Consulta Publicada con exito';
                    break;
                case 'goback':
                    await service.gobackDocument(state.id);
                    setState(service.consulta.value);
                    text = 'Consulta reabierta con exito';
                    break;
                case 'send':
                    await service.updateConsulta(state.id, state);
                    await service.resendDocument(state.id, data.emails);
                    text = 'Consulta enviada con exito';
                    break;
                case 'resend':
                    await service.updateConsulta(state.id, state);
                    await service.resendDocument(state.id);
                    text = 'Consulta reenviada con exito';
                    break;
                case 'build_docs':
                    await service.buildDocuments(state.id);
                    setState(service.consulta.value);
                    text = 'Documentos generados con exito';
                    break;
                case 'render_template':
                    await service.updateConsulta(state.id);
                    await service.renderTemplate(state.id, data.templateId);
                    setState(service.consulta.value);
                    break;
                case 'render_evolucion':
                    await service.updateConsulta(state.id, state);
                    await service.renderEvolucion(state.id, data?.index);
                    setState(service.consulta.value);
                    text = 'Evolucion agregada con exito';
                    break;
                case 'remove_render':
                    await service.updateConsulta(state.id, state);
                    await service.removeRender(state.id, data.renderId);
                    setState(service.consulta.value);
                    text = 'Archivo eliminado con exito';
                    break;
                case 'generate_evolucion':
                    break;
                case 'generate_gafas':
                    break;
                case 'generate_ordenes':
                    break;
                case 'generate_receta':
                    break;
                case 'update_observacion':
                    store.dispatch('ContentManager/openWindow', {
                        id: `ConsultaOftalmologiaForm_${service.consulta.value.id}`,
                        component: 'ConsultaOftalmologiaForm',
                        name: `Consulta ${service.consulta.value.consecutive}`,
                        params: {
                            title: 'Consulta',
                            headerTitle: `Crear Observación ${service.consulta.value.consecutive}`,
                            item: { ...service.consulta.value },
                            action: 'observacion',
                        },
                    });
                    break;
                default:
                    break;
            }
            if (text) {
                snackbar.add({
                    type: 'success',
                    text,
                });
            }
        } else {
            console.log(v.$errors);
            snackbar.add({
                type: 'error',
                text: 'Ups no podemos enviar la informacion sin algunos datos',
            });
        }
    };

    const setInstitucion = (value) => {
        if (!state.documentStatus || state.documentStatus === '1') {
            if (state.institucion !== value.id) state.institucion = value.id;
        }
    };

    const handleAddNewObservacion = (data) => {
        state.observaciones.push(data.observacion);
        if (state.id) handleAction('save_sh');
    };

    const handledeleteNewObservacion = (data) => {
        // console.log('data.index', data.index);
        state.observaciones = state.observaciones.filter((o, i) => {
            console.log('i', i);
            return i !== data.index;
        });
        if (state.id) handleAction('save_sh');
    };

    return {
        v,
        state,
        disabled,
        setState,
        resetForm,
        handleAction,
        handleAddNewObservacion,
        handledeleteNewObservacion,
        setInstitucion,
        formType,
    };
}
